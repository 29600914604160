import { AxiosInstance } from 'axios';
import format from 'string-format';

import {
  AvatarType,
  InventoryCommunitySlotType,
  InventoryItemType,
  InventorySlotItemType,
  InventorySlotType,
  InventoryType,
  ItemRarity,
} from '@/lib/features/inventory/types';
import { Endpoint } from '@/lib/infra/api/constants';

export function getInventoryByContainerId(api: AxiosInstance, id: string) {
  return api
    .get<InventoryType>(format(Endpoint.GET_INVENTORY, { communityId: id }))
    .then((res) => res.data);
}

export function getInventorySlots(api: AxiosInstance, communityId: string) {
  return api
    .get<{ slots: InventorySlotType[] }>(
      format(Endpoint.GET_INVENTORY_SLOTS, {
        communityId,
      }),
    )
    .then((res) => res.data);
}

export function getInventorySlotItems(
  api: AxiosInstance,
  {
    communityKey,
    slotId,
    filters,
  }: {
    communityKey: string;
    slotId: string;
    filters: { itemRarity?: ItemRarity; onlyOwned?: boolean };
  },
) {
  const searchParams = new URLSearchParams();
  if (filters.itemRarity) {
    searchParams.append('filter[rarity][]', filters.itemRarity);
  }
  if (filters.onlyOwned) {
    searchParams.append('onlyOwned', 'true');
  }

  const baseUrl = format(Endpoint.GET_INVENTORY_ITEMS, {
    communityKey,
    slotId,
  });

  const url = `${baseUrl}${
    searchParams.size ? `?${searchParams.toString()}` : ''
  }`;

  return api
    .get<{
      slot: InventoryCommunitySlotType;
      items: InventorySlotItemType[];
      total: number;
      offset: number;
    }>(url)
    .then((res) => res.data);
}

export function getInventoryCategoryItems(
  api: AxiosInstance,
  inventoryId: string,
  categoryId: string,
) {
  return api
    .get(
      format(Endpoint.GET_INVENTORY_CATEGORY_ITEMS, {
        inventoryId,
        categoryId,
      }),
    )
    .then((res) => res.data);
}

export function getInventoryAvatar(api: AxiosInstance, communityId: string) {
  return api
    .get<{ avatar: AvatarType | null }>(
      format(Endpoint.GET_INVENTORY_AVATAR, { communityId }),
    )
    .then((res) => res.data);
}

export function getInventoryItem(
  api: AxiosInstance,
  {
    communityKey,
    itemId,
  }: {
    communityKey: string;
    itemId: string;
  },
) {
  return api
    .get<InventoryItemType | null>(
      format(Endpoint.GET_INVENTORY_ITEM, {
        communityKey,
        itemId,
      }),
    )
    .then((res) => res.data);
}

export function allocateInventorySlots(
  api: AxiosInstance,
  communityKey: string,
  slots: { slotId: string; itemId: string | null }[],
) {
  return api
    .post(format(Endpoint.ALLOCATE_INVENTORY_SLOT, { communityKey }), {
      slots,
    })
    .then((res) => res.data);
}

export function postMintAvatar(api: AxiosInstance, communityKey: string) {
  return api
    .post(format(Endpoint.MINT_AVATAR, { communityKey }), {
      blockchain: 'SUI',
    })
    .then((res) => res.data);
}
