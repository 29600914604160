import { AxiosInstance } from 'axios';

import {
  ReferralListType,
  USER_AUTH_STATE,
  UserProfileSocialType,
  UserProfileType,
  UserReferralType,
} from '@/lib/features/auth/types';
import { getDisplayName, getUserState } from '@/lib/features/auth/utils';
import { Endpoint } from '@/lib/infra/api/constants';

export async function getUserProfile(api: AxiosInstance) {
  return api.get<UserProfileType>(Endpoint.GET_PROFILE).then((res) => res.data);
}

export async function getUserSocials(api: AxiosInstance) {
  return api
    .get<{ results: UserProfileSocialType[] }>(Endpoint.SOCIALS)
    .then((res) => res.data.results);
}

export function getUserReferredList(
  api: AxiosInstance,
): Promise<UserReferralType[]> {
  return api
    .get<ReferralListType>(Endpoint.REFERRALS)
    .then((res) => res.data?.referrals);
}

export function getProfile(
  api: AxiosInstance,
  slim?: boolean,
  isAuthenticated?: boolean,
) {
  return async (): Promise<{
    isLoggedIn: boolean;
    state: USER_AUTH_STATE;
    displayName: string;
    profile?: UserProfileType;
    socials?: UserProfileSocialType[];
    referredList?: UserReferralType[];
  }> => {
    if (isAuthenticated) {
      const profile = await getUserProfile(api);
      const state = getUserState(profile);

      const base = {
        profile,
        state,
        isLoggedIn: state === USER_AUTH_STATE.POST_CLAIM,
        displayName: getDisplayName(
          profile.userId,
          profile.profile?.handle,
          profile.profile?.displayName,
        ),
      };

      if (!slim) {
        const socials = await getUserSocials(api);
        const referredList = await getUserReferredList(api);

        return {
          ...base,
          socials,
          referredList,
        };
      } else {
        return base;
      }
    }

    return {
      isLoggedIn: false,
      state: USER_AUTH_STATE.NOT_REGISTERED,
      displayName: 'Guest',
    };
  };
}
