import { Endpoint } from './constants';

export function isUnauthorizedCall(url: string) {
  const unauthorizedCalls = [
    Endpoint.REFRESH_TOKEN,
    Endpoint.LOGIN_GOOGLE,
    Endpoint.LOGIN_OAUTH,
  ];

  return unauthorizedCalls.some((call) => url.endsWith(call));
}
