import { QueryClient, QueryClientConfig } from '@tanstack/react-query';

export const CLIENT_QUERY_KEYS = {
  ALL_COMMUNITIES: 'ALL_COMMUNITIES',
  ALL_EVENTS: 'ALL_EVENTS',
  ALL_GAMES: 'ALL_GAMES',
  ALL_JOINED_COMMUNITIES: 'ALL_JOINED_COMMUNITIES',
  ALL_LEADER_BOARD: 'ALL_LEADER_BOARD',
  ALL_QUESTS: 'ALL_QUESTS',
  COMING_UP_EVENT: 'COMING_UP_EVENT',
  COMMUNITY_EVENTS: 'COMMUNITY_EVENTS',
  COMMUNITY_INVENTORY: 'COMMUNITY_INVENTORY',
  COMMUNITY_LEADERBOARD: 'COMMUNITY_LEADERBOARD',
  COMMUNITY_LEADERBOARD_PAGINATED: 'COMMUNITY_LEADERBOARD_PAGINATED',
  COMMUNITY_QUEST_FILTERS: 'COMMUNITY_QUEST_FILTERS',
  COMMUNITY_QUESTS: 'COMMUNITY_QUESTS',
  COMMUNITY_QUEST_TAGS: 'COMMUNITY_QUEST_TAGS',
  COMMUNITY_RANKING: 'COMMUNITY_RANKING',
  COMMUNITY_RESOURCES: 'COMMUNITY_RESOURCES',
  COMMUNITY_XP: 'COMMUNITY_XP',
  COMMUNITY_SLOT_ITEMS: 'COMMUNITY_SLOT_ITEMS',
  EVENT_DETAILS: 'EVENT_DETAILS',
  EVENT_USER_RANK: 'EVENT_USER_RANK',
  FEATURED_EVENTS: 'FEATURED_EVENTS',
  GAME_DETAILS: 'GAME_DETAILS',
  GET_USER_PROFILE: 'GET_USER_PROFILE',
  GET_USER_PROFILE_SLIM: 'GET_USER_PROFILE_SLIM',
  INVENTORY_ASSET_COMMUNITY: 'INVENTORY_ASSET_COMMUNITY',
  INVENTORY_AVATAR: 'INVENTORY_AVATAR',
  INVENTORY_CATEGORY_ITEMS: 'INVENTORY_CATEGORY_ITEMS',
  INVENTORY_ITEM: 'INVENTORY_ITEM',
  INVENTORY_SLOT_ITEMS: 'INVENTORY_SLOT_ITEMS',
  INVENTORY_SLOTS: 'INVENTORY_SLOTS',
  INVENTORY: 'INVENTORY',
  LIVE_AND_UPCOMING_EVENTS: 'LIVE_AND_UPCOMING_EVENTS',
  ONGOING_EVENTS: 'ONGOING_EVENTS',
  QUEST_DETAILS: 'QUEST_DETAILS',
  QUEST_RESULTS: 'QUEST_RESULTS',
  QUEST_EVENT_LEADERBOARD: 'QUEST_EVENT_LEADERBOARD',
  QUEST_EVENT_PARTICIPANT: 'QUEST_EVENT_PARTICIPANT',
  QUEST_EVENT: 'QUEST_EVENT',
  QUEST_HISTORY: 'QUEST_HISTORY',
  QUEST_LOAD_CONTEXT: 'QUEST_LOAD_CONTEXT',
  QUEST_FILTERS: 'QUEST_FILTERS',
  RESOURCE_BY_ID: 'RESOURCE_BY_ID',
  SINGLE_COMMUNITY: 'SINGLE_COMMUNITY',
  USER_RANK: 'USER_RANK',
  MINT_AVATAR: 'MINT_AVATAR',
  EXTRA_WOW_CHARACTERS: 'EXTRA_WOW_CHARACTERS',
};

const CACHE_TIME = 10 * 60 * 1000;
const STALE_TIME = 8 * 60 * 1000;

export const queryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      staleTime: STALE_TIME,
      gcTime: CACHE_TIME,
      refetchInterval: CACHE_TIME,
      refetchOnWindowFocus: false,
      networkMode: 'offlineFirst',
    },
  },
};

export function getQueryClient(config?: QueryClientConfig) {
  return new QueryClient(config ?? queryClientConfig);
}
