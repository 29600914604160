import { AxiosInstance } from 'axios';
import format from 'string-format';

import {
  QuestDetailsType,
  QuestsFiltersType,
  TagType,
} from '@/lib/features/quests/types';
import { urlfy } from '@/lib/helpers/urlfy';
import { Endpoint } from '@/lib/infra/api/constants';

export function getCommunityQuests(
  api: AxiosInstance,
  id: string,
  filters?: QuestsFiltersType,
) {
  return api
    .get<QuestDetailsType[]>(
      format(Endpoint.GET_COMMUNITY_QUESTS, { id }) + urlfy(filters),
    )
    .then((res) => res.data);
}

export function getCommunityQuestFilters(api: AxiosInstance, id: string) {
  return api
    .get<{ tags: TagType[] }>(
      format(Endpoint.GET_COMMUNITY_QUEST_TAGS, { id }) +
        '?excludeTemplateTags=true&userAvailableQuests=true',
    )
    .then((res) => res.data.tags ?? []);
}

export function getQuestHistory(
  api: AxiosInstance,
  filters?: QuestsFiltersType,
) {
  return api
    .get<QuestDetailsType[]>(Endpoint.GET_QUESTS_HISTORY + urlfy(filters))
    .then((res) => res.data);
}
