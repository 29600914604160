import { AxiosInstance } from 'axios';
import format from 'string-format';

import type { ResourceType } from '@/lib/features/community/types';
import { Endpoint } from '@/lib/infra/api/constants';

export function getCommunityResources(
  api: AxiosInstance,
  communityId: string,
): Promise<ResourceType[]> {
  return api
    .get(format(Endpoint.GET_COMMUNITY_RESOURCE, { id: communityId }))
    .then((res) => res.data)
    .then((data) => data.resourceTypes);
}

export function getResourceById(
  api: AxiosInstance,
  resourceTypeId: string,
): Promise<ResourceType> {
  return api
    .get(format(Endpoint.GET_COMMUNITY_RESOURCE, { id: resourceTypeId }))
    .then((res) => res.data);
}
