export enum InventoryActionTypes {
  GET_INVENTORY = 'INVENTORY/GET_INVENTORY_ITEMS',
  GET_INVENTORY_FULFILLED = 'INVENTORY/GET_INVENTORY_ITEMS_FULFILLED',
  GET_INVENTORY_PENDING = 'INVENTORY/GET_INVENTORY_ITEMS_PENDING',
  GET_INVENTORY_REJECTED = 'INVENTORY/GET_INVENTORY_ITEMS_REJECTED',

  GET_INVENTORY_SLOTS = 'inventory/GET_INVENTORY_SLOTS',
  GET_INVENTORY_SLOTS_PENDING = 'inventory/GET_INVENTORY_SLOTS_PENDING',
  GET_INVENTORY_SLOTS_FULFILLED = 'inventory/GET_INVENTORY_SLOTS_FULFILLED',
  GET_INVENTORY_SLOTS_REJECTED = 'inventory/GET_INVENTORY_SLOTS_REJECTED',

  GET_INVENTORY_SLOT_ITEMS = 'inventory/GET_INVENTORY_SLOT_ITEMS',
  GET_INVENTORY_SLOT_ITEMS_PENDING = 'inventory/GET_INVENTORY_SLOT_ITEMS_PENDING',
  GET_INVENTORY_SLOT_ITEMS_FULFILLED = 'inventory/GET_INVENTORY_SLOT_ITEMS_FULFILLED',
  GET_INVENTORY_SLOT_ITEMS_REJECTED = 'inventory/GET_INVENTORY_SLOT_ITEMS_REJECTED',

  GET_INVENTORY_CATEGORY_ITEMS = 'inventory/GET_INVENTORY_CATEGORY_ITEMS',
  GET_INVENTORY_CATEGORY_ITEMS_PENDING = 'inventory/GET_INVENTORY_CATEGORY_ITEMS_PENDING',
  GET_INVENTORY_CATEGORY_ITEMS_FULFILLED = 'inventory/GET_INVENTORY_CATEGORY_ITEMS_FULFILLED',
  GET_INVENTORY_CATEGORY_ITEMS_REJECTED = 'inventory/GET_INVENTORY_CATEGORY_ITEMS_REJECTED',

  GET_INVENTORY_AVATAR = 'inventory/GET_INVENTORY_AVATAR',
  GET_INVENTORY_AVATAR_PENDING = 'inventory/GET_INVENTORY_AVATAR_PENDING',
  GET_INVENTORY_AVATAR_FULFILLED = 'inventory/GET_INVENTORY_AVATAR_FULFILLED',
  GET_INVENTORY_AVATAR_REJECTED = 'inventory/GET_INVENTORY_AVATAR_REJECTED',

  ALLOCATE_INVENTORY_SLOTS = 'inventory/ALLOCATE_INVENTORY_SLOTS',
  ALLOCATE_INVENTORY_SLOTS_PENDING = 'inventory/ALLOCATE_INVENTORY_SLOTS_PENDING',
  ALLOCATE_INVENTORY_SLOTS_FULFILLED = 'inventory/ALLOCATE_INVENTORY_SLOTS_FULFILLED',
  ALLOCATE_INVENTORY_SLOTS_REJECTED = 'inventory/ALLOCATE_INVENTORY_SLOTS_REJECTED',

  RESET_INVENTORY = 'inventory/RESET_INVENTORY',
}

export interface InventoryInterface {
  inventory: {
    data?: InventoryType;
    status: INVENTORY_STATUS_TYPE;
  };
  inventoryCategory: {
    list?: InventorySlotContainerItemType[];
    status: INVENTORY_STATUS_TYPE;
  };
  avatar: {
    data?: AvatarType;
    status: INVENTORY_STATUS_TYPE;
  };
}

export type AvatarType = {
  avatarId: string;
  mediaUrl: string;
  type: string;
  updatedAt?: string | null;
};

export type InventoryType = {
  inventory: null | {
    containerId: string;
    inventoryId: string;
    metadata: {};
    slug: string;
    type: string;
    slots?: InventorySlotType[];
  };
};

export type InventorySlotType = {
  slotId: string;
  required: boolean;
  slug: string;
  categories?: InventorySlotCategoryType[];
  createdAt: string;
  updatedAt: string;
  status: 'published' | 'draft';
  publishedAt: string | null;
  inventoryId: string;
  editableValue: boolean;
  defaultItemId: string | null;
};

// This type is returned from slot items response
export type InventorySlotItemType = {
  itemId: string;
  slug: string;
  name: string;
  mediaUrl: string;
  supplyLimit: number | null;
  description: string;

  inventoryCategoryId: string;
  rarity: ItemRarity;
  balance?: number;
  defaultItemId: string | null;
  accessType: 'private' | 'public';
  acquisitionRules: InventoryAcquisitionRule[];
};

// This type is returned from slot items response
export type InventoryCommunitySlotType = {
  slotId: string;
  required: boolean;
  slug: string;
  defaultItemId: string | null;
  editableValue: boolean;
  allocated?: {
    slotId: string;
    itemId: string;
    allocatedId: string;
    item: InventorySlotItemType;
  };
};

export type InventorySlotCategoryType = {
  containerItems: InventorySlotContainerItemType[];
  categoryHierarchyId: string;
  name: string;
};

export type InventorySlotContainerItemType = {
  containerItemId: string;
  item: InventorySlotContainerItemItemType;
};

export type InventorySlotContainerItemItemType = {
  description: string;
  slug: string;
  id: string;
  metadata: {
    rarity: INVENTORY_ITEM_RARITY;
  };
  name: string;
  path: string;
};

export type InventoryDataType = {
  backgrounds: InventoryItemType[];
  skins: InventoryItemType[];
  effects: InventoryItemType[];
  frames: InventoryItemType[];
};

export enum ItemRarity {
  RARE = 'rare',
  COMMON = 'common',
  EPIC = 'epic',
  LEGENDARY = 'legendary',
  ELITE = 'elite',
  HEROIC = 'heroic',
  MYTHIC = 'mythic',
}
export type InventoryItemType = {
  itemId: string;
  slug: string;
  description: string;
  inventoryCategoryId: string;
  accessType: 'private' | 'public';
  rarity: INVENTORY_ITEM_RARITY;
  status: 'draft' | 'published';
  name: string;
  mediaUrl: string;
  supplyLimit: number | null;
  balance?: number;
  acquisitionRules: InventoryAcquisitionRule[];
};

type InventoryAcquisitionRule = {
  acquisitionRuleId: string;
  acquisitionType: 'reward';
  itemId: string;
  rule: {
    rewardId: string;
    questType: 'quest';
    questTypeId: string;
  };
};

export type InventoryItemRequirementType = {
  type: INVENTORY_ITEM_REQUIREMENT_TYPE;
  title: string;
};

export enum INVENTORY_ITEM_REQUIREMENT_TYPE {
  COMMUNITY = 'COMMUNITY',
  MISSION = 'MISSION',
  OBTAIN_XP = 'OBTAIN_XP',
}

export enum INVENTORY_ITEM_RARITY {
  COMMON = 'common',
  RARE = 'rare',
  EPIC = 'epic',
  LEGENDARY = 'legendary',
  ELITE = 'elite',
  HEROIC = 'heroic',
  MYTHIC = 'mythic',
}

export enum INVENTORY_ITEM_STATUS {
  PURCHASABLE = 'PURCHASABLE',
  SOLD_OUT = 'SOLD_OUT',
  MINT = 'MINT',
  COMING_SOON = 'COMING_SOON',
}

export enum INVENTORY_STATUS_TYPE {
  UNINITIALIZED = 'UNINITIALIZED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  FULFILLED = 'FULFILLED',
}
