import { TagProps } from '../types';

export enum QuestsActionTypes {
  GET_QUESTS = 'quests/GET_QUESTS',
  GET_QUESTS_FULFILLED = 'quests/GET_QUESTS_FULFILLED',
  GET_QUESTS_PENDING = 'quests/GET_QUESTS_PENDING',
  GET_QUESTS_REJECTED = 'quests/GET_QUESTS_REJECTED',

  UPDATE_QUESTS = 'quests/UPDATE_QUESTS',
  UPDATE_QUESTS_FULFILLED = 'quests/UPDATE_QUESTS_FULFILLED',
  UPDATE_QUESTS_PENDING = 'quests/UPDATE_QUESTS_PENDING',
  UPDATE_QUESTS_REJECTED = 'quests/UPDATE_QUESTS_REJECTED',

  GET_QUESTS_HISTORY = 'quests/GET_QUESTS_HISTORY',
  GET_QUESTS_HISTORY_FULFILLED = 'quests/GET_QUESTS_HISTORY_FULFILLED',
  GET_QUESTS_HISTORY_PENDING = 'quests/GET_QUESTS_HISTORY_PENDING',
  GET_QUESTS_HISTORY_REJECTED = 'quests/GET_QUESTS_HISTORY_REJECTED',

  GET_QUEST_DETAILS = 'quests/GET_QUEST_DETAILS',
  GET_QUEST_DETAILS_FULFILLED = 'quests/GET_QUEST_DETAILS_FULFILLED',
  GET_QUEST_DETAILS_PENDING = 'quests/GET_QUEST_DETAILS_PENDING',
  GET_QUEST_DETAILS_REJECTED = 'quests/GET_QUEST_DETAILS_REJECTED',

  UPDATE_QUEST_DETAILS = 'quests/UPDATE_QUEST_DETAILS',
  UPDATE_QUEST_DETAILS_FULFILLED = 'quests/UPDATE_QUEST_DETAILS_FULFILLED',
  UPDATE_QUEST_DETAILS_REJECTED = 'quests/UPDATE_QUEST_DETAILS_REJECTED',

  REMOVE_QUEST_DETAILS = 'quests/REMOVE_QUEST_DETAILS',

  COMPLETE_QUEST = 'quests/COMPLETE_QUEST',
  COMPLETE_QUEST_FULFILLED = 'quests/COMPLETE_QUEST_FULFILLED',
  COMPLETE_QUEST_REJECTED = 'quests/COMPLETE_QUEST_REJECTED',

  ACCEPT_QUEST = 'quests/ACCEPT_QUEST',
  ACCEPT_QUEST_FULFILLED = 'quests/ACCEPT_QUEST_FULFILLED',
  ACCEPT_QUEST_REJECTED = 'quests/ACCEPT_QUEST_REJECTED',

  REFRESH_QUEST = 'quests/REFRESH_QUEST',
  REFRESH_QUEST_FULFILLED = 'quests/REFRESH_QUEST_FULFILLED',
  REFRESH_QUEST_REJECTED = 'quests/REFRESH_QUEST_REJECTED',

  GET_QUESTS_FILTERS = 'quests/GET_QUESTS_FILTERS',
  GET_QUESTS_FILTERS_FULFILLED = 'quests/GET_QUESTS_FILTERS_FULFILLED',
  GET_QUESTS_FILTERS_PENDING = 'quests/GET_QUESTS_FILTERS_PENDING',
  GET_QUESTS_FILTERS_REJECTED = 'quests/GET_QUESTS_FILTERS_REJECTED',

  LOAD_QUEST_CONTEXT = 'quests/LOAD_QUEST_CONTEXT',
}

export interface QuestsInterface {
  data: {
    list?: QuestDetailsType[];
    status: QUEST_STATUS_TYPE;
  };
  filters: {
    list?: string[];
    status: QUEST_STATUS_TYPE;
  };
  details: {
    quest?: QuestDetailsType;
    status: QUEST_STATUS_TYPE;
  };
}

export enum ParticipantSyncStatusType {
  NONE = 'NONE',
  SYNCING = 'SYNCING',
  SYNCED = 'SYNCED',
}

export type QuestEvent = {
  eventId: string;
  title: string;
  description: string;
  status: QUEST_STATUS;
  containerId: string;
  maxParticipants: number;
  totalParticipants: number;
  participation: {
    isParticipating: boolean;
    syncStatus: ParticipantSyncStatusType;
  };
  quests: QuestType[];
  rewards: Array<
    {
      resolutionType: 'ABSOLUTE' | 'RELATIVE';
      resolutionValue: number;
      rewardArgs: {
        [key: string]: string;
      };
    } & QuestRewardType
  >;
  registrationEndDate: string;
};

export type QuestType = QuestDetailsType & {
  questId: string;
  questType: QUEST_TYPE;
  containerId: string;
  published: boolean;
  closed: boolean;
  template: QuestTemplateType;
  reward: QuestRewardType;
  status: QUEST_STATUS;
  title: string;
  description: string;
};

export type QuestRewardType = {
  rewardId?: string;
  name: string;
  handler?: string;
  args: Record<string, string>;
  resource?: any;
};

export type QuestDetailsType = {
  questId: string;
  questType: QUEST_TYPE;
  containerId: string;
  template: QuestTemplateType;
  reward: QuestRewardType;
  published: boolean;
  createdAt: Date;
  updatedAt: Date;
  publishedAt: Date;
  createdBy: string;
  updatedBy: string;
  publishedBy: string;
  closed: boolean;
  title: string;
  description: string;
  feedback?: string;
  status: QUEST_STATUS;
  metrics: Record<string, string>;
  completionCount: number;
  completedAt: Date;
  tags: TagType[];
  statusInfo: QUEST_STATUS_INFO;
  attemptCount: number;
  attemptLimit: number;
  parent?: QuestDetailsType;
  lockedPrerequisites?: QuestDetailsType[];
  resourceEmission?: number;
  community?: {
    name?: string;
  };
  order?: number;
};

export type TagType = {
  tagId: string;
  slug: string;
  label: string;
  totalQuests: number;
  containerId: string;
  completedQuests?: number; // only available if a call is made with auth
};

export type QuestTemplateType = {
  templateId: string;
  name: QUEST_TEMPLATE_NAME;
  handler: string;
  args: Record<string, string>;
  tags?: TagProps[];
  supportedTypes: QUEST_TYPE[];
};

export enum QUEST_TYPE {
  LEADERBOARD = 'Leaderboard',
  SOCIAL = 'Social',
  GAMING = 'Gaming',
  OBJECTIVE = 'Objective',
}

export type QUEST_TEMPLATE_NAME =
  | GAMING_QUEST_TEMPLATE_NAME
  | COMPLETEONLY_QUEST_TEMPLATE_NAME
  | ACCEPTONLY_QUEST_TEMPLATE_NAME;

export enum GAMING_QUEST_TEMPLATE_NAME {
  // APEIRON
  APEIRON_COMPLETE_DEPTH = 'apeiron.complete-depth',
  APEIRON_ACHIEVEMENT = 'apeiron.achievement',

  // STEAM
  STEAM_PLAY_GAME_X_HOURS = 'steam.play-game-x-hours',
  STEAM_PLAY_X_HOURS = 'steam.play-x-hours',

  // CS2
  CSGO_WINRATE = 'cs2.win-rate',
  CSGO_MVP_TOTAL = 'cs2.mvp-total',
  CSGO_KILL_COUNT = 'cs2.kill-count',
  CSGO_KILL_COUNT_PER_GAMES = 'cs2.kill-count-per-games',
  CSGO_KDA = 'cs2.kd',
  CSGO_MVP = 'cs2.mvp',
  CSGO_GAMES_WON = 'cs2.games-won',
  CSGO_GAMES_PLAYED = 'cs2.games-played',
  CSGO_WINRATE_PER_GAMES = 'cs2.winrate-per-games',
  CSGO_MVP_PER_GAMES = 'cs2.mvp-per-games',
  CSGO_HEADSHOT = 'cs2.headshot-rate',
  CSGO_KILLS_TO_DEATH_RATIO = 'cs2.kills-to-deaths-ratio',
  CSGO_BOMBS_PLANTED = 'cs2.bombs-planted',
  CSGO_DAMAGE = 'cs2.damage',
  CSGO_ENEMY_WEAPON_KILLS = 'cs2.enemy-weapon-kills',
  CSGO_HEADSHOT_KILLS = 'cs2.headshot-kills',
  CSGO_KNIFE_KILLS = 'cs2.knife-kills',
  CSGO_MONEY_EARNED = 'cs2.money-earned',
  CSGO_PISTOL_ROUND = 'cs2.pistol-round',

  // Dota 2
  DOTA2_WINRATE_PER_GAME = 'dota2.winrate-per-games',
  DOTA2_AS_HERO = 'dota2.win-with-hero',
  DOTA2_LAST_HIT = 'dota2.last-hit',
  DOTA2_TOWER_DAMAGE = 'dota2.tower-damage',
  DOTA2_DENIES = 'dota2.denies',
  DOTA2_KILL_COUNT = 'dota2.kill-count',
  DOTA2_LAST_HIT_PER_GAMES = 'dota2.last-hit-per-games',
  DOTA2_KD = 'dota2.kd',

  // Faceit 2
  FACEIT_SKILL_LEVEL = 'faceit.skill-level',
  FACEIT_ELO = 'faceit.faceit-elo',
  FACEIT_LEADERBOARD_RANKING = 'faceit.hub-leaderboard-ranking',
  FACEIT_HUB_MEMBERSHIP = 'faceit.hub-membership',

  // League of Legends
  LOL_RANK = 'lol.rank',
  LOL_IMPROVE_RANK = 'lol.improve-rank',
  LOL_WINRATE = 'lol.winrate',
  LOL_TOTAL_WINS = 'lol.total-wins',
  LOL_ASSISTS = 'lol.assists',
  LOL_ARAM_DAMAGE = 'lol.aram-damage',
  LOL_DODGE_SKILLSHOTS = 'lol.dodge-skillshots-aram',
  LOL_EARLY_TAKEDOWNS = 'lol.early-takedown',
  LOL_FIRST_BLOOD = 'lol.first-blood',
  LOL_INVADE_ENEMY_JUNGLE = 'lol.invade-enemy-jungle',
  LOL_KILL_COUNT = 'lol.kill-count',
  LOL_MONSTER_SPAWN_KILLS = 'lol.monster-spawn-kill',
  LOL_PENTAKILL_ARAM = 'lol.pentakill-aram',
  LOL_PENTAKILL = 'lol.pentakill',
  LOL_RANKED_DUO_WIN = 'lol.ranked-duo-win',
  LOL_S_GRADE_ARAM = 'lol.s-grade-aram',
  LOL_S_GRADE_RIFT = 'lol.s-minus-grade',
  LOL_SOLO_KILLS = 'lol.solo-kills',
  LOL_STEAL_EPIC_MONSTERS = 'lol.steal-epic-monsters',
  LOL_DESTROYED_TURRETS_BEFORE_PLATES = 'lol.destroyed-turrets-before-plates',
  LOL_TURRET_TAKEDOWN = 'lol.turret-takedown',
  LOL_WARD_TAKEDOWN = 'lol.ward-takedown',
  LOL_HIT_SNOWBALL = 'lol.snowball-aram',

  // Team Fight Tactics
  TFT_RANK = 'tft.rank',
  TFT_IMPROVE_RANK = 'tft.improve-rank',
  TFT_WINRATE = 'tft.winrate',
  TFT_TOTAL_WINS = 'tft.total-wins',

  // World of Warcraft
  WOW_PLAY_X_GAMES = 'wow.play-x-games',
  WOW_WIN_X_GAMES = 'wow.win-x-games',
  WOW_WINRATE = 'wow.winrate',
}

export enum COMPLETEONLY_QUEST_TEMPLATE_NAME {
  // APEIRON
  APEIRON_PLAY_10_MINUTES = 'apeiron.play-10-min',

  // TWITTER
  TWITTER_FOLLOW = 'twitter.follow',
  TWITTER_LIKE = 'twitter.like',
  TWITTER_QUOTE = 'twitter.quote',
  TWITTER_RETWEET = 'twitter.retweet',
  TWITTER_TWEET_HAS_HASHTAG = 'twitter.has_hashtag',
  TWITTER_TWEET_HAS_LINK = 'twitter.has_link',

  // DISCORD
  DISCORD_CHECKROLE = 'discord.check-role',
  DISCORD_CHECKGUILD = 'discord.check-guild',

  // TWITCH
  TWITCH_FOLLOW = 'twitch.follow',
  TWITCH_SUBSCRIBE = 'twitch.subscribe',

  // GLOBALS
  CHECK_RESOURCE = 'basic.check-resource',
  REFERRAL_COUNT = 'basic.referral-count',

  // YOUTUBE
  YOUTUBE_SUBSCRIBE = 'youtube.subscribe',
  YOUTUBE_LIKE = 'youtube.like',
  VISIT_YOUTUBE = 'youtube.visit-channel',
  WATCH_YOUTUBE = 'youtube.watch-video',

  // STEAM
  STEAM_WISHLIST = 'steam.wishlist-game',

  // BASIC
  ANSWER_SUBMISSION = 'basic.answer-submission',
  VISIT_LINK = 'basic.visit-link',
  QUIZ_QUEST = 'basic.quiz',
  POLL_QUEST = 'basic.poll',
  COMMUNITY_REFERRAL = 'basic.membership-referral-count',
  CHECK_LEVEL = 'basic.check-level',
  AUTO_COMPLETE = 'basic.auto-complete',
  VIEW_INSTAGRAM_POST = 'basic.view-instagram-post',
  VISIT_FACEBOOK = 'basic.visit-facebook',
  VISIT_INSTAGRAM = 'basic.visit-instagram',
}

export enum ACCEPTONLY_QUEST_TEMPLATE_NAME {
  TEXT_SUBMISSION = 'basic.text-submission',
  IMAGE_SUBMISSION = 'manual.image-submission',
  SUBMIT_WALLET = 'basic.submit-wallet',
  SUBMIT_EMAIL = 'basic.submit-email',
}

export enum QUEST_STATUS {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  ABANDONED = 'ABANDONED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  RESOLVING = 'RESOLVING',
}

export enum QUEST_STATUS_INFO {
  EMPTY = 'EMPTY',
  ERROR_QUEST_EXPIRED = 'ERROR_QUEST_EXPIRED',
  ERROR_COMPLETION_EXCEEDED = 'ERROR_COMPLETION_EXCEEDED',
  RETRYING = 'RETRYING',
  ERROR_MAX_RETRIES_EXCEEDED = 'ERROR_MAX_RETRIES_EXCEEDED',
  VALIDATION_SUCCESSFUL = 'VALIDATION_SUCCESSFUL',
  ERROR_VALIDATION_FAILED = 'ERROR_VALIDATION_FAILED',
  OAUTH_TOKEN_REFRESH_FAILED = 'OAUTH_TOKEN_REFRESH_FAILED',
}

export enum QUEST_STATUS_TYPE {
  UNINITIALIZED = 'UNINITIALIZED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  FULFILLED = 'FULFILLED',
}

export enum QUEST_REWARD_ROLE_TYPE {
  PRE_LAUNCH_WHITELIST = 'PRE_LAUNCH_WHITELIST',
}

export enum QUEST_REWARD_NAMES {
  PROGRESS = 'quest_progress',
  CREDIT_RESOURCE = 'credit_resource',
}

export type QuestsFiltersType = {
  tags?: string[];
  types?: string[];
  containerIds?: string[];
  [`templateFilter[name]`]?: string;
  [`questFilter[title]`]?: string;
  [`entryFilter[_status]`]?: string;
};
