const VERSION = `/v1`;

export const Endpoint = {
  // USER
  LOGIN: `${VERSION}/user/auth/password/login`,
  LOGIN_GOOGLE: `${VERSION}/user/auth/google/login`,
  LOGIN_WEB3: `${VERSION}/user/auth/siwe/login`,
  LOGIN_OAUTH: `${VERSION}/user/auth`,
  REFRESH_TOKEN: `${VERSION}/user/auth/refresh-session`,
  PICK_GAMES: `${VERSION}/user/pick-games`,

  GET_PROFILE: `${VERSION}/user/profile`,
  EDIT_PROFILE: `${VERSION}/user/profile`,
  DELETE_ACCOUNT: `${VERSION}/user/delete`,
  SOCIALS: `${VERSION}/user/socials`,
  UPDATE_SOCIALS: `${VERSION}/user/socials/update`,
  GET_USER_GAMES: `/user/games`,
  GET_USER_COMMUNITIES: `/user/communities`,
  REFERRALS: `v1/user/referrals`,

  // SUBSCRIPTION
  SUBSCRIBE: `${VERSION}/subscribe`,

  // QUESTS
  GET_QUEST: `${VERSION}/quests/{id}`,
  GET_QUEST_RESULTS: `${VERSION}/quests/{id}/results`,
  GET_QUESTS: `${VERSION}/quests`,
  GET_QUESTS_FILTERS: `${VERSION}/quests/tags`,
  GET_QUESTS_HISTORY: `${VERSION}/quests/history`,
  GET_QUESTS_HISTORY_FILTERS: `${VERSION}/quests/historyTags`,
  ACCEPT_QUEST: `${VERSION}/quests/{id}/accept`,
  COMPLETE_QUEST: `${VERSION}/quests/{id}/complete`,
  REFRESH_QUEST: `${VERSION}/quests/{id}/refresh`,
  LOAD_QUEST_CONTEXT: `${VERSION}/quests/{id}/load-context`,

  GET_QUEST_EVENT: `${VERSION}/quest-event/{id}`,
  GET_QUEST_EVENT_LEADERBOARD: `${VERSION}/quest-event/{id}/leaderboard`,

  // LEADERBOARD
  GET_LEADERBOARD: `${VERSION}/leaderboards/{resourceTypeId}/rank`,
  GET_USER_RANK: `${VERSION}/community/{communityId}/ranking/{resourceTypeId}`,

  // GAMING
  GET_ALL_GAMES: `${VERSION}/games`,
  GET_GAME_DETAILS: `${VERSION}/games/{id}`,
  GET_GAME_QUESTS: `${VERSION}/games/{gid}/quests`,

  // COMMUNITIES
  GET_ALL_COMMUNITIES: `${VERSION}/community`,
  GET_COMMUNITY: `${VERSION}/community/{slug}`,
  GET_COMMUNITY_QUESTS: `${VERSION}/community/{id}/quests`,
  GET_COMMUNITY_LEADERBOARD: `${VERSION}/community/{communityId}/leaderboard/{resourceTypeId}`,
  GET_COMMUNITY_XP: `${VERSION}/community/{communityId}/leaderboard/xp`,
  GET_COMMUNITY_RANKING_XP: `${VERSION}/community/{id}/ranking/xp`,
  GET_COMMUNITY_QUEST_TAGS: `${VERSION}/community/{id}/quests/tags`,
  GET_COMMUNITY_RESOURCES: `${VERSION}/community/{id}/resource-types`,
  GET_COMMUNITY_RESOURCE: `${VERSION}/community/resource-types/{id}`,
  JOIN_COMMUNITY: `${VERSION}/community/{id}/join`,
  LEAVE_COMMUNITY: `${VERSION}/community/{id}/leave`,

  // INVENTORY
  GET_INVENTORY: `${VERSION}/community/{communityId}/inventory`,
  GET_INVENTORY_SLOTS: `${VERSION}/community/{communityId}/inventory/slots`,
  GET_INVENTORY_ITEMS: `${VERSION}/community/{communityKey}/inventory/slots/{slotId}/items`,
  GET_INVENTORY_ITEM: `${VERSION}/community/{communityKey}/inventory/items/{itemId}`,
  GET_INVENTORY_CATEGORY_ITEMS: `${VERSION}/community/{communityId}/inventory/{inventoryId}/categories/{categoryId}/items`,
  GET_INVENTORY_AVATAR: `${VERSION}/community/{communityId}/inventory/avatar`,
  ALLOCATE_INVENTORY_SLOT: `${VERSION}/community/{communityKey}/inventory/slots/allocate`,
  MINT_AVATAR: `${VERSION}/community/{communityKey}/inventory/avatar/mint`,

  // EVENTS
  GET_EVENTS: `${VERSION}/quest-event`,
  GET_EVENT_DETAILS: `${VERSION}/quest-event/{id}`,
  GET_EVENT_PARTICIPANT: `${VERSION}/quest-event/{id}/participant`,
  GET_EVENT_USER_RANK: `${VERSION}/quest-event/{id}/participant/rank`,
  PARTICIPATE_EVENT: `${VERSION}/quest-event/{eventId}/participant/register`,
  LEAVE_EVENT: `${VERSION}/quest-event/{eventId}/participant/unregister`,
  GET_EVENT_LEADERBOARD: `${VERSION}/quest-event/{eventId}/leaderboard`,
  SYNC_EVENT: `${VERSION}/quest-event/{eventId}/participant/sync`,

  LEADERBOARD: `${VERSION}/leaderboards/{resourceTypeId}/rank`,
  USER_RANK: `${VERSION}/leaderboards/{resourceTypeId}/rank/user`,

  EXTRA_WOW_CHARACTERS: `${VERSION}/user/socials/extra/wow/characters`,

  GET_PUBLIC_CONFIG: `${VERSION}/config`,
  GET_PRIVATE_CONFIG: `${VERSION}/config/private`,
};

export enum LOCAL_STORAGE_VALUE {
  COOKIE_CONSENT = 'c',
  SITE_COLOR = 'sc',
  QUESTS_LIST_MINIMIZED = 'qlm',
  QUESTS_REFRESH_TIMER = 'qrt',
  QUEST_EVENT_SYNC_TIMER = 'qert',
}
